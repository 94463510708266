<template>
  <v-card
    v-if="custom_data_tables.length === 0 && modulesCheck"
    max-width="600"
    class="modules-list-empty mt-5"
  >
    <v-card-title class="justify-center">
      {{$t('modules.op.texts.empty_modules_tables_title')}}
    </v-card-title>

    <v-card-text>
      {{$t('modules.op.texts.empty_modules_tables_text')}}
    </v-card-text>
  </v-card>

  <v-layout
    v-else
    class="cd__wrapper"
  >
    <v-overlay
      absolute

      :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-navigation-drawer
      class="cd__drawer"
      v-model="drawer"
      :width="drawer ? '356' : '0'"
      :floating="isDesktop ? false : true"
      :fixed="isDesktop ? false : true"
      :temporary="isDesktop ? false : true"
      :disable-resize-watcher="true"
      mini-variant-width="356"
    >
      <v-sheet class="cd__drawer--wrapper">
        <div v-if="false" class="px-5" :class="{ 'cd__drawer--wrapper-top' : !isDesktop }">
          <div>
            <v-overflow-btn
              flat
              outlined
              class="cd__drawer--select my-2"
              :items="dropdown_filters"
              label="Global Filter"
              segmented
              target="#dropdown-example-2"
              color="red"
              full-width
              hide-details
              dense
              :clearable="selectedGFilter"
              v-model="selectedGFilter"
              elevation="0"
              prepend-inner-icon="mdi-filter"
            ></v-overflow-btn>

            {{selectedGFilter}}
          </div>
          <div>
            <!-- add close button for drawer -->
            <div class="cd__drawer-close" @click.stop="drawer = !drawer" v-if="!isDesktop">
              <v-icon>mdi-close</v-icon>
            </div>
            <!-- add close button for drawer -->
          </div>
        </div>

        <v-sheet
          class="cd__drawer--wrapper-body mt-5"
          height="300"
        >
          <vue-scroll>
            <div class="cd__drawer-filters__items px-5">
              <GlobalFilterCntrl v-if="globalFilterExist" />
              <div class="cd__drawer-filters__item">
                <div class="cd__drawer-filters__item-title">Дата створення</div>
                <date-picker
                  class="cd__drawer-filters__date"
                  lable="Data"
                  v-model="dates"
                  type="date"
                  valueType="YYYY-MM-DD"
                  format="YYYY-MM-DD"
                  range
                  @clear="dates = []; saveFirstGlobalFilter()"
                  :placeholder="$t('modules.statistics.texts.filters.label_choose_range_dates')"
                  :lang="$i18n.locale"
                  :disabled-date="datepickerDisabledInFuture"
                  :disabled-calendar-changer="datepickerDisabledInFuture"
                />
              </div>
              <div class="cd__drawer-global-filter-exist" v-if="globalFilterExist">
                <GlobalFilterContent />
              </div>
              <div v-else-if="false">
                <div class="text-center mb-2 text--secondary" v-if="!dynamicSelects">Создайте фильтр</div>
                <div class="mb-4 cd__drawer-global-filter-item" v-for="(item, index) in dynamicSelects" :key="index">
                  <div class="cd__drawer-global-filter-item-content">
                    <v-select
                      outlined
                      dense
                      hide-details
                      :items="custom_data_tables_grouped"
                      item-text="name"
                      return-object
                      v-model="vmodelGlobalFilterModel[index]"
                      @change="changeGlobalFilterModelSelect(index)"
                      placeholder="Выберите модель"
                    ></v-select>

                    <v-combobox
                      v-if="itemsForGlobalFilterColumn[index]"
                      outlined
                      dense
                      hide-details
                      item-text="label"
                      return-object
                      v-model="vmodelGlobalFilterColumn[index]"
                      @change="changeGlobalFilterColumnSelect(index)"
                      :items="itemsForGlobalFilterColumn[index]"
                      placeholder="Выберите колонку"
                    />
                  </div>
                  <div class="cd__drawer-global-filter-item-delete" @click.stop="deleteGlobalFilterItem(index)">
                    <v-icon>mdi-close</v-icon>
                  </div>
                </div>
              </div>
              <div class="cd__drawer-filters__add-button">
                <v-btn
                  v-if="false"
                  color="primary"
                  small
                  outlined
                  @click.stop="addItemIntoGlobalFilter"
                  elevation="0"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>

              <!-- <div
                v-for="(item, i) in [1,2,3,4,5,6,7,8]"
                :key="i"
                class="cd__drawer-filters__item"
              >
                <div class="cd__drawer-filters__item-title">Активність {{ i + 1}}</div>
                <v-select
                  outlined
                  dense
                  :items="['Active', 'Hold', 'In progress']"
                  hide-details
                  placeholder="Будь-яка"
                />
              </div> -->
            </div>
          </vue-scroll>

        </v-sheet>
      </v-sheet>

      <template v-slot:append v-if="globalFilterExist">
        <v-layout
          justify-space-between
          class="px-5 pb-5 pt-2"
        >
          <v-btn
            small
            color="primary"
            elevation="0"
          >Застосувати</v-btn>

          <v-btn
            small
            color="primary"
            outlined
            elevation="0"
          >Зберегти</v-btn>
        </v-layout>
      </template>
      <template v-slot:append v-else>
        <v-layout
          justify-space-between
          class="px-5 pb-5 pt-2"
        >
          <v-btn
            small
            color="primary"
            elevation="0"
            width="100%"
            @click.stop="saveFirstGlobalFilter"

          ><v-icon small>mdi-filter</v-icon></v-btn>
          <!-- :disabled="disabledButtonForGlobalFilter" -->
        </v-layout>
      </template>
    </v-navigation-drawer>

    <div
      :class="{ 'statistics-showed' : dynamicStatisticsShowed }"
      class="cd__container"
    >
      {{ /* Top navigation for table  */ }}
      <div class="cd__top-nav">
        <div class="cd__right">
          <!-- add toggle class -->
          <v-layout align-center="" class="cd__tabs mb-2" :class="{ 'cd__tabs--hide-for-cd-actions': cdActionsMobileView}">
            <div
              class="cd__drawer--toggle-filter"
            >
              <v-select
                class="cd__drawer--toggle-filter__select"
                outlined
                dense
                :items="dropdown_filters"
                v-model="selectedGFilter"
                hide-details
                flat
                background-color="white"
                v-if="false && !drawer"
              >
                <template v-slot:prepend-inner>
                  <v-icon
                    :color="selectedGFilter ? 'primary' : ''" >mdi-filter</v-icon>
                </template>
                <template v-slot:selection="{ item, index }"></template>
              </v-select>

              <v-btn
                class="cd__drawer--toggle-filter__btn-arrow"
                :color="dates.length ? 'success' : 'primary'"
                small
                @click.stop="drawer = !drawer"
                fab
                elevation="0"
              >
                <v-icon v-if="drawer">mdi-chevron-left</v-icon>
                <v-icon v-else>mdi-chevron-right</v-icon>
              </v-btn>
            </div>


            <v-autocomplete
              :value="tabActive"
              @input="loadData($event)"
              :items="custom_data_tables_grouped"
              item-text="description"
              return-object
              outlined
              color="primary"
              dense
              height="40"
              min-height="40"
              max-height="40"
              class="cd__tabs--select"
              hide-details
              background-color="white"
            ></v-autocomplete>

            <!-- add tooltip for mobile view -->
            <div class="ml-5 text-body-2 cd__tabs-mobile-toggle">
              {{multiple_entity}}
            </div>
            <div class="cd__tabs-mobile-toggle cd__tabs-tooltip-for-select">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon> mdi-information-outline </v-icon>
                  </div>
                </template>
                <span>{{model_description}}</span>
              </v-tooltip>
            </div>
          </v-layout>

          <!-- Disabled -->
<!--          <v-tabs-->
<!--            v-if="false"-->
<!--            background-color="transparent"-->
<!--            class="cd__tabs mb-2"-->
<!--            height="35"-->
<!--            slider-size="2"-->
<!--            v-model="tabActive"-->
<!--            @change="loadData(custom_data_tables[tabActive])"-->
<!--          >-->

<!--            <v-tooltip-->
<!--              top-->
<!--              v-for="( item, i ) in custom_data_tables"-->
<!--              :key="i"-->
<!--            >-->
<!--              <template-->
<!--                v-slot:activator="{on}"-->
<!--              >-->
<!--                <v-tab-->
<!--                  v-on="on"-->
<!--                  @click="changeTabRoute(item)"-->
<!--                  :title="item.description"-->
<!--                >-->
<!--                  {{ item.name }}-->
<!--                </v-tab>-->
<!--              </template>-->
<!--              <span>{{item.description}}</span>-->
<!--            </v-tooltip>-->

<!--          </v-tabs>-->
          <!-- end Disabled -->

          <v-spacer></v-spacer>

          <!-- add class toggle -->
          <v-btn
            class="cd__actions--toggle-actions__btn-arrow"
            :class="{
                  'cd__actions--toggle-actions__btn-arrow--opened': cdActionsMobileView,
                }"
            color="primary"
            small
            @click.stop="changeCdActions"
            fab
            elevation="0"
          >
            <v-icon v-if="!cdActionsMobileView">mdi-chevron-left</v-icon>
            <v-icon v-else>mdi-chevron-right</v-icon>

          </v-btn>

          <div class="cd__actions" :class="{ 'cd__actions--visible': cdActionsMobileView }">
            {{ /** ADD BUTTON **/}}
            <v-btn
              v-if="checkPermission('canAdd')"
              elevation="0"
              color="primary"
              height="30"
              class="mr-2"
              @click="openModal('create_mode')"
            >
              <v-icon left> mdi-plus </v-icon>
              <span class="cd__actions-mobile-hide">{{ i18n.t('modules.customdata.entity.add') }}</span>
            </v-btn>
            {{ /** end ADD BUTTON **/}}

            <!-- Reload Button -->
            <v-btn
              color="primary"
              icon
              small
              class="mx-1"
              :loading="loading"
              @click="getItems(false)"
            >
              <v-icon>mdi-sync</v-icon>
            </v-btn>
            <!-- end Reload Button -->

            {{ /* Search */ }}
            <v-layout
              class="custom-data__search"
            >
              <v-text-field
                class="pt-0 mt-0 mr-2"
                v-model="searchInTable"
                @input="handleSearchInput()"
                @keydown.enter="handleSearchInput('onEnter')"
                prepend-inner-icon="mdi-magnify"
                :label="$t('modules.customdata.entity.search')"
                single-line
                hide-details
                height="30"
                clearable
                @click:clear="applyFilter(true)"
                @focus="isSearchActive = true"
                @blur="disableSearchActive"
              ></v-text-field>
            </v-layout>
            {{ /* end Search */ }}

            {{ /* menu Actions for Table */ }}
            <v-menu
              offset-y
              rounded='sm'
              bottom
              min-width="248"
              nudge-bottom="12"
              nudge-left="14"
              content-class="v-custom-menu__list elevation-0"
              :close-on-content-click=false
              :disabled="isSearchActive"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  height="30"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  class="v-custom-menu__list--btn mr-2"
                >
                  <v-icon class="mr-1" small>mdi-gamepad-circle-down</v-icon>
                  <span class="cd__actions-mobile-hide">{{ $t("modules.customdata.entity.actions") }}</span>
                </v-btn>
              </template>

              <v-list dense>
                <div class="v-custom-menu__list--wrapper">
                  <v-list-item-group>

                    <v-list-item
                      v-if="canSave"
                      :disabled="selected.length < 1"
                      :color="selected.length < 1 ? 'gray1' : ''"
                      @click="mappingMultipleItems('clone')"
                    >
                      <v-list-item-icon>
                        <v-icon
                          :color="selected.length < 1 ? 'gray1' : ''"
                        >mdi-content-duplicate</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Clone
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      v-if="canSave"
                      :disabled="selected.length < 1"
                      :color="selected.length < 1 ? 'gray1' : ''"
                      @click="deleteSelectedItem(selected)"
                    >
                      <v-list-item-icon>
                        <v-icon
                          :color="selected.length < 1 ? 'gray1' : ''"
                        >mdi-delete-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{i18n.t('modules.customdata.entity.del_selected')}}
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-group>
                      <template v-slot:activator>
                        <v-list-item-icon>
                          <v-icon>mdi-download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                          {{i18n.t('modules.customdata.entity.export')}}
                        </v-list-item-title>
                      </template>

                      <v-list-item
                        v-for="(item, index) in exportType"
                        :key="index"
                        v-on:click="getExportFile(item)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                    </v-list-group>

                    <v-list-item v-if="false">
                      <v-list-item-icon>
                        <v-icon>mdi-upload</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        Import
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="canSave">
                      <v-list-item-icon>
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        @click="clearModel"
                      >{{ $t('modules.customdata.clear') }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </div>
              </v-list>
            </v-menu>
            {{ /* end menu Actions for Table */ }}

            {{ /* Show/Hide Filters and Saved Filters List */ }}
            <v-layout
              align-center
              class="v-custom-menu__list--filter-btns mr-2"
              :class="{ 'active' : toggleActiveFilter }"
            >
              <v-btn
                class="filter-btns__btn-show"
                color=""
                elevation="0"
                @click="showFilters = !showFilters"
                text
              >
                <v-icon class="mr-1" small v-if="!showFilters">mdi-filter</v-icon>
                <v-icon class="mr-1" small v-else color="primary">mdi-filter-off</v-icon>
                <span class="cd__actions-mobile-hide">{{ $t("modules.customdata.entity.filter") }}</span>
              </v-btn>

              <v-divider
                v-if="custom_data_saved_filters.length"
                vertical />

              <v-menu
                v-if="custom_data_saved_filters.length"
                offset-y
                rounded='sm'
                bottom
                min-width="208"
                nudge-bottom="12"
                nudge-left="14"
                :close-on-content-click=false
                content-class="v-custom-menu__list elevation-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    elevation="0"
                    text
                    v-bind="attrs"
                    v-on="on"
                    @click="setHeaderColumnsFromState()"
                    class="filter-btns__btn-menu"
                  >
                    <!-- <v-icon v-if="attrs['aria-expanded'] === 'true'">mdi-menu-up</v-icon>
                    <v-icon v-else>mdi-menu-down</v-icon> -->
                    <v-icon small>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>

                <v-list dense class="pb-0">
                  <v-subheader>{{ $t("modules.customdata.entity.saved_filters") }}</v-subheader>

                  <div class="v-custom-menu__list--wrapper with_fixed-height">
                    <vue-scroll>
                      <v-list-item-group
                        v-model="toggleActiveFilter"
                        color="primary"
                      >
                        <v-list-item
                          v-for="(item, i) in custom_data_saved_filters"
                          :key="`item-${i}`"
                          :value="item"
                          v-ripple="false"
                        >
                          <v-list-item-content>
                            <v-list-item-title

                              v-text="item.name"></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-icon
                            v-if="canSave"
                            @click.stop="onDeleteFilter(item.name)"
                            class="d-flex align-center justify-center"
                          ><v-icon size="14" color="red">$icondelete</v-icon>
                          </v-list-item-icon>

                        </v-list-item>
                      </v-list-item-group>

                      <v-divider></v-divider>

                      {{/* Show all (clear filters) */}}
                      <v-list-item-group>
                        <v-list-item
                          @click="clearAllFilters"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="$t('modules.customdata.entity.show_all')"
                            />
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                      {{/* end Show all (clear filters) */}}

                    </vue-scroll>
                  </div>

                </v-list>
              </v-menu>

            </v-layout>

            {{ /* end Show/Hide and Filters List */ }}

            {{ /* Show/Hide table headers toggle */ }}
            <v-menu
              offset-y
              rounded='sm'
              bottom
              min-width="208"
              max-height="500"
              nudge-bottom="12"
              nudge-left="14"
              :close-on-content-click=false
              content-class="v-custom-menu__list elevation-0"
              :disabled="isSearchActive"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  height="30"
                  color="transparent"
                  elevation="0"
                  v-bind="attrs"
                  v-on="on"
                  class="v-custom-menu__list--btn mr-2"
                >
                  <v-icon
                    small
                    class="mr-1"
                  >
                    <!-- $vuetify.icons.iconeyeno -->
                    mdi-eye-off
                  </v-icon>
                  <!-- <v-icon v-if="attrs['aria-expanded'] === 'true'">mdi-menu-up</v-icon>
                  <v-icon v-else>mdi-menu-down</v-icon> -->
                  <span class="cd__actions-mobile-hide">{{ $t("modules.customdata.entity.hide") }}</span>
                </v-btn>
              </template>

              <v-list dense>
                <v-subheader>
                  {{ $t("modules.customdata.entity.title_column_chooser") }}
                  <v-icon
                    class="ml-2"
                    color="primary"
                    v-text="get_custom_data_is_global_columns ? 'mdi-account-multiple' : 'mdi-account'"
                  />
                </v-subheader>
                <div class="v-custom-menu__list--wrapper with_fixed-height">
                  <vue-scroll>
                    <v-list-item-group multiple>
                      <draggable
                        @end="customDataChangeOrderColumn($event)"
                        handle=".drag_btn"
                        class="list-group"
                        :disabled="!enabled"
                        ghost-class="draggable-ghost"
                      >
                        <template
                          v-for="(item, i) in headerColumns">
                          <v-list-item
                            :key="`item-${i}`"
                            :value="item"
                            v-ripple="false"
                            :class="'dataitemname__' + item.name"
                          >

                          <span class="drag_btn">
                            <v-icon>mdi-drag</v-icon>
                          </span>
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="item.active"
                                color="primary"
                                dense
                                @change="
                                customDataToggleColumnActive(
                                  {
                                    name: item.name,
                                    value: $event,
                                  }
                                )"
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>

                          </v-list-item>
                        </template>
                      </draggable>
                    </v-list-item-group>
                  </vue-scroll>
                </div>

                <v-layout
                  v-if="canSave"
                  align-center
                  class="col-chooser-btn-wrap px-6 pt-3 pb-2"
                >
                  <v-btn
                    small
                    color="primary"
                    elevation="0"
                    class="mr-6"
                    @click="
                    custom_data_toggle_active_filter || !save_global_columns
                    ? onSaveFilters({
                      name: save_global_columns ? custom_data_toggle_active_filter.name : null,
                      with_columns: true
                    })
                    : customDataAxiosSaveColumnsData({name: multiple_entity, reset: false})
                  "
                    v-text="
                    custom_data_toggle_active_filter
                    ? $t('modules.customdata.columns_chooser.save_columns_for_filter')
                    : $t('modules.customdata.columns_chooser.save_columns')
                  "
                  ></v-btn>

                  <v-btn
                    small
                    color="primary"
                    outlined
                    elevation="0"
                    @click="
                      custom_data_toggle_active_filter || !save_global_columns
                      ? save_global_columns ? resetColumnsChooserForFilter() : onDeleteFilter(null)
                      : resetColumnsChooser()
                    "
                    :disabled="!save_global_columns && !custom_data_toggle_active_filter && get_custom_data_is_global_columns"
                  >{{ $t("modules.customdata.columns_chooser.clear_columns") }}</v-btn>
                </v-layout>

                <!-- save like global -->
                <div v-if="canSave && !custom_data_toggle_active_filter">
                  <v-divider />
                  <v-layout :justify-center="true" :align-center="true">
                    <v-checkbox
                      class="mt-1"
                      :label="$t('modules.customdata.entity.save_global_columns_label')"
                      v-model="save_global_columns"
                      hide-details
                      :ripple="false"
                    />
                  </v-layout>
                </div>
                <!-- end save like global -->

              </v-list>

            </v-menu>
            {{ /* end Show/Hide table headers toggle */ }}

            {{ /* Show/Hide dynamic statistics */ }}
            <v-btn
              height="30"
              color="transparent"
              elevation="0"
              @click="dynamicStatisticsShowed = !dynamicStatisticsShowed; scrollToComponent()"
              v-if="dynamicStatisticsAvailable"
            >
              <v-icon
                :color="dynamicStatisticsShowed ? 'primary' : ''"
                small
                class="mr-1"
              >
                mdi-poll
              </v-icon>
              {{ $t("modules.customdata.statistics") }}
            </v-btn>
            {{ /* end Show/Hide dynamic statistics */ }}

            <!-- addition CRUD mode for backend -->
            <template
              v-if="additional_crud_filter?.enabled"
            >
              <v-checkbox
                v-model="additional_crud_filter_value"
                :label="additional_crud_filter.label || ''"
                hide-details
                dense
                @change="getItems(false)"
                :disabled="loading"
                class="mt-0 ml-2 addition_crud_filter"
              />
            </template>
            <!-- End addition check filter for backend -->
          </div>
        </div>
      </div>
      {{ /* end Top navigation for table */ }}

      <div
        class="cd__bottom-nav"
        v-if="load"
      >
        <div
          class="cd__left"
          v-show="showFilters"

        >
          {{ /* Filters Component */ }}
          <DynamicFilters
            :selected_filters="custom_data_selected_filters"
            :filtersList="custom_data_filters.filter(obj => obj.hidden_all !== 'true')"
            :custom_linked_tables_values="custom_data_linked_tables_values"
            @apply="applyFilter()"
            :savedFiltersList="custom_data_saved_filters"
            @save="onSaveFilters($event)"
            @delete-filter="onDeleteFilter($event)"
            @reset-columns="resetColumnsChooserForFilter()"

            :action_toggle_active_filter="'customDataSetToggleActiveFilter'"
            :action_selected_filters_empty="'customDataSetSelectedFiltersEmpty'"
            :action_delete_selected_filter_in_array="'customDataDeleteSelectedFilterInArray'"

            :action_set_selected_filter_value="'customDataSetSelectedFilterValue'"
            :action_set_selected_filter_condition="'customDataSetSelectedFilterCondition'"

            :canSave="canSave"
          />
          {{ /* end Filters Component */ }}
        </div>

        <div class="cd__right cd__table">
          {{ /* selected table description */ }}
          <!-- <div class="table-descr">{{model_description}}</div> -->

          <DynamicTable
            :items="items || []"
            :headers="headers"
            :selected.sync="selected"
            :selectedHeaders="custom_data_columns_data"
            :searchInTable="searchInTable"
            :loading="loading"
            :items-length="length"
            :page-count="pageCount"
            :default-per-page="defaultPerPage"
            :items-per-page="itemsPerPage"
            :multiple_entity="multiple_entity"
            :entity_fields="entity_fields"
            :ui_table_stylization="getUITableStylization()"
            :macros_params="macros_params"
            @change-default-per-page="changeDefaultPerPage($event)"
            @change-page="changePage($event)"
            @sortOptions="onSort"
            @edit-item="editItem($event)"
            @delete-item="deleteItem($event)"
            @update-list="getItems"
            :default-sort="'id'"
            select_active
            :propTotalRow="showTotalRowInTable"
            ref="DynamicTable"
            class="dynamic-table"
          />

          <DynamicStatistics
            :filters="setFiltersObject()"
            :multiple_entity="multiple_entity"
            ref="DynamicStatistics"
            v-if="dynamicStatisticsShowed"
            :isShowed="dynamicStatisticsShowed"
          />

        </div>
      </div>

      {{/** MODAL WINDOW EDIT/ADD ENTITY **/}}
      <template v-if="dynamicModalEntityAvailable">
        <DynamicModalEntity
          v-if="modulesCheck"
          ref="modalEntityForm"
          v-model="modalDynamic"
          :canSave="canSave"
          :modal_create_mode="modal_create_mode"
          :multiple_entity="multiple_entity"

          :entity_fields="entity_fields"
          :item_obj="item_obj"
          :headers="headers"

          @save="save"
          @deleteItemDrawer="deleteItemDrawer"
        />
      </template>

      <DynamicMainModalEntity
        v-if="modulesCheck"
        ref="modalMainEntityForm"
        v-model="modal"
        :canSave="canSave"
        :modal_create_mode="modal_create_mode"
        :multiple_entity="multiple_entity"
        :headers="headers"

        :entity_fields="entity_fields"
        :item_obj="item_obj"

        @save="save"
        @deleteItemDrawer="deleteItemDrawer"
      />

      <DynamicModalMultipleEntity
        v-if="modulesCheck && modalMultiple"
        v-model="modalMultiple"
        :canSave="canSave"
        :modal_create_mode="modal_multiple_entities_mode"
        :hidden_in_create_popup="getModalMultipleEntitiesByField('hidden_in_create_popup')"
        :selected_entities="selected_entities"
        ref="modalMultipleEntityForm"

        :multiple_entity="multiple_entity"
        :headers="headers"
        :entity_fields="entity_fields"

        @save="selected = []; getItems(false)"
      />

    </div>
  </v-layout>

</template>

<script>
import DynamicTable from "./../../components/DynamicTable.vue";
import DynamicStatistics from "./../../components/DynamicStatistics.vue";
import DynamicFilters from "./../../components/DynamicFilters.vue";
import {mapActions, mapGetters} from "vuex";
import draggable from 'vuedraggable';

import customDataMixin from './customDataMixin';
import globalFilterMixin from './globalFilterMixin';
import newComponentsMixin from './newComponentsMixin';
import Vue from "vue";
import axios from "axios";
import i18n from '@/lang/i18n';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/uk';
import 'vue2-datepicker/locale/ru';
import 'vue2-datepicker/locale/en';
import 'vue2-datepicker/locale/de';

import GlobalFilterContent from './GlobalFilter/GlobalFilterContent.vue'
import GlobalFilterCntrl from "./GlobalFilter/GlobalFilterCntrl.vue";

// new components
import DynamicModalEntity from "@/modules/CustomData/components/DynamicModalEntity.vue";
import DynamicMainModalEntity from "@/modules/CustomData/components/DynamicMainModalEntity.vue";
import DynamicModalMultipleEntity from "@/modules/CustomData/components/DynamicModalMultipleEntity.vue";

export default {
  name: "CustomDataTab",

  mixins: [customDataMixin, globalFilterMixin, newComponentsMixin],

  components: {
    DynamicTable,
    DynamicFilters,
    DynamicStatistics,
    draggable,
    DatePicker,
    GlobalFilterContent,
    GlobalFilterCntrl,
    DynamicModalEntity,
    DynamicMainModalEntity,
    DynamicModalMultipleEntity,
  },

  data: () => ({
    overlay: false,
    i18n,
    enabled: true,

    drawer: false,
    // add new variable for mobile view of actions panel
    cdActionsMobileView: false,
    dropdown_filters: [
      { text: 'Global Filter', callback: () => console.log('list') },
      { text: 'list', callback: () => console.log('list') },
      { text: 'favorite', callback: () => console.log('favorite') },
      { text: 'delete', callback: () => console.log('delete') },
      { text: 'Very very long name custom filter', callback: () => console.log('delete') },
    ],
    selectedGFilter: null,

    dates: [],
    // dates: [
    //   `${new Date().toISOString().slice(0, 10)} 00:00`,
    //   `${new Date().toISOString().slice(0, 10)} 23:59`,
    // ],

    //table data
    headers: [],
    selectedHeaders: [],
    headerColumns: [],

    // counters
    defaultPerPage: 20,
    itemsPerPage: [5, 10, 20, 50, 100],
    pageCount: 0,
    loading: false,

    //search table
    searchInTable: "",

    //Filters data
    filtersList: {},

    //selected table rows
    selected: [],

    dafaultDataInitial: {
      "offset": 0,
      "limit": 20,
      "order": {
        "sortBy": "id",
        "sortDesc": true,
      }
    },

    dataInitial: {
      "offset": 0,
      "limit": 20,
      "order": {
        "sortBy": "id",
        "sortDesc": true,
      }
    },

    //new
    load: false,
    modulesCheck: false,
    multiple_entity: null,
    model_description:null,
    entity_fields: [],
    entity: null,

    length: null,

    item_obj: {
      id: null,
    },
    item_empty_obj: {},
    multipleSelectItems: [],

    entity_data: null,

    modal: false,
    form_valid: true,
    modal_create_mode: false,

    exportType: ['csv', 'xls', 'pdf', 'xml', 'html'],

    showFilters: false,
    filters: [],

    linkedTablesValues: {},
    tabActive: null,

    dynamicStatisticsShowed: false,
    dynamicStatisticsAvailable: false,

    isSearchActive: false,


    modalDynamic: false,
    dynamicModalEntityAvailable: false,

    modal_multiple_entities_mode: null,
    modalMultiple: false,
    selected_entities: [],

    macros_params: null,

    save_global_columns: false,

    additional_crud_filter: null,
    additional_crud_filter_value: false,
  }),

  mounted: async function () {
    await this.CustomDataAxiosGetTables({
      botId: this.$route?.params?.id
    });

    if(this.custom_data_tables?.length) {
      if(this.$route?.params?.model_name) {
        const table = this.custom_data_tables.filter( (t) => t.name === this.$route.params.model_name )

        this.multiple_entity = table[0].name
        this.model_description = table[0].description
        this.entity_fields = table[0].columns_json

        //set Active Tab
        this.tabActive = table[0]

        this.modulesCheck = true

      } else {
        this.multiple_entity = this.custom_data_tables[0]?.name
        this.model_description = this.custom_data_tables[0].description
        this.entity_fields = this.custom_data_tables[0].columns_json
        this.changeTabRoute(this.custom_data_tables[0])

        //set Active Tab
        this.tabActive = this.custom_data_tables[0]

        this.modulesCheck = true
      }
    }

    this.$root.$on('CustomDataTab', async (request) => {
      await this.saveCustomEntityList(request, request.id)
      await this.getItems(false);
    })

    this.customDataAxiosGetGroups();
  },

  computed: {
    ...mapGetters([
      'user',
      'rules',
      'custom_data_tables',
      'custom_data_filters',
      'custom_data_linked_tables_values',
      'custom_data_selected_filters',
      'custom_data_saved_filters',
      'custom_data_columns_data',
      'custom_data_columns_data_activated',
      'custom_data_saved_columns_data',
      'custom_data_default_columns_data',

      'custom_data_toggle_active_filter',
      'get_custom_data_groups',

      'engine_settings',
      'bot_settings',

      'get_custom_data_is_global_columns',
    ]),

    items: function () {
      let items = [];

      if (this.entity_data) {
        for (let item of this.entity_data) {
          if (item.id) {
            items.push(item);
          }
        }
      }
      return items;
    },

    //saved Filters ist toggle
    toggleActiveFilter: {
      get: function() {
        return this.custom_data_toggle_active_filter
      },
      set: async function(value) {
        if (!value) value = null
        await this.customDataSetToggleActiveFilter(value)
        this.resetPagination()
        this.changeDefaultPerPage(this.defaultPerPage)

        //for updating columns list in this component
        this.setHeaderColumnsFromState()
        // this.headerColumns = this._.cloneDeep(this.custom_data_columns_data)

        //add filter name in query url
        await this.$router.replace({
          'query':
            value ? {'filter': value.name} : null
        });
      },
    },

    canSave() {
      return this.user &&
        this.user.role.permissions.custom_data.sections.custom_data.canSave &&
        this.checkPermission('canSave')

    },

    //show total Row in table from custom stylization
    showTotalRowInTable() {
      return this.custom_data_tables
        ?.find((t) => t.name === this.multiple_entity && t?.front_json?.table_stylization)?.front_json?.table_stylization?.total_row;
    },

  },

  methods: {
    ...mapActions([
      'CustomDataAxiosGetTables',
      'CustomDataAxiosClearModel',
      'customDataToggleColumnActive',
      'customDataChangeOrderColumn',
      'customDataAxiosSaveColumnsData',
      'customDataResetColumnsDataToDefault',
      'customDataResetColumnsDataForFilterToSaved',
      'customDataSetToggleActiveFilter',
      'customDataAxiosSaveFilters',
      'customDataAxiosGetGroups',
    ]),
    onSort(data){
      if (data.sortBy.length > 0){
        this.dataInitial.order.sortBy = data.sortBy[0];
        this.dataInitial.order.sortDesc = data.sortDesc[0];
        this.getItems(false);
      }
    },

    clearAllFilters() {
      this.$root.$emit('clear-all-filters');
    },

    resetPagination() {
      this.$refs.DynamicTable.resetPagination();
    },

    /**
     * @name changeDefaultPerPage
     * @param value
     * @description change default page in pagination for child
     */
    changeDefaultPerPage(value) {
      this.defaultPerPage = value;
      this.dataInitial.offset = 0;
      this.dataInitial.limit = this.defaultPerPage;
      this.getItems(false);
    },

    /**
     * @method
     * @name changePage
     * @description server side Pagination
     * @param value
     */
    changePage(value) {
      this.dataInitial.offset = value > 1 ? (this.defaultPerPage * value) - this.defaultPerPage : 0;
      this.getItems(true);
    },

    /**
     * @name loadData
     * @description trigger axios on tab with tables changed
     * @param item
     * @returns {Promise<void>}
     */
    async loadData(item) {
      this.multiple_entity = item.name
      this.model_description = item.description
      this.entity_fields = item.columns_json

      this.changeTabRoute(item)
    },

    async applyFilter(clearSearch = false) {
      if(clearSearch) this.searchInTable = ""
      this.dataInitial = JSON.parse(JSON.stringify(this.dafaultDataInitial));
      this.dataInitial.limit = this.defaultPerPage;
      await this.$refs.DynamicTable.resetPagination()
      await this.getItems(false)
    },

    /**
     * @name getItems
     * @description main trigger function for Get items
     * @returns {Promise<void>}
     */
    async getItems(updateHeaders = true) {
      if(this.$refs && this.$refs.DynamicStatistics) {
        this.$refs.DynamicStatistics.getCustomEntityStatistics();
      }
      this.entity_data = await this.getCustomEntityList(updateHeaders);
    },

    /**
     * @name getCustomEntityList
     * @description axios for get Items
     * @param multiple_entity
     * @returns {Promise<null>}
     */
    async getCustomEntityList(updateHeaders = true) {
      let data = null;

      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/list`

      const params = {
        limit: this.dataInitial?.limit,
        offset: this.dataInitial?.offset,
        order: {
          [this.dataInitial?.order?.sortBy]: this.dataInitial?.order?.sortDesc ? 'desc' : 'asc'
        },
      }

      if(this.searchInTable) params['search'] = this.searchInTable;

      //set filters
      if(Object.keys(this.custom_data_selected_filters).length !== 0) {
        params['filter'] = this.setFiltersObject()
      }

      // Additional CRUD Filter
      if(
        this.additional_crud_filter?.enabled
        && this.additional_crud_filter_value === this.additional_crud_filter?.condition
      ) params['additional_crud_filter'] = true
      // end Additional CRUD Filter

      // this.$store.dispatch('updateAjaxDialog', [true, true]);
      this.loading = true
      this.overlay = true

      await axios({
        method: 'POST',
        headers: {
          'X-CSRF-TOKEN': token,
        },
        url: url,
        params: {
          bot_id: this.$route?.params?.id,
        },
        data: params,
      })
        .then( (response) => {
          // this.$store.dispatch('updateAjaxDialog', [false, false]);
          this.loading = false

          if ( response?.data?.status === 'success' ) {
            const columns = response.data.data.columns;
            data = response.data.data.rows;
            this.length = response.data.data.count;
            this.pageCount = Math.ceil(response.data.data.count / this.defaultPerPage);

            //update headers
            if(updateHeaders) {
              //set headers without association columns
              this.headers = []

              //fields with associations
              const associationColumns = columns.filter((c) => c?.association)

              for (let c in columns) {
                let item = {
                  align: 'start',
                  name: columns[c].name,
                  rules: columns[c]?.rules || [],
                  show_in_popup: true,
                  show_in_table: true,
                  hidden_in_table: columns[c].hidden_in_table,
                  hidden_in_create_popup: columns[c]?.hidden_in_create_popup, //
                  hidden_all: columns[c].hidden_all,
                  sortable: columns[c].sortable,
                  text: columns[c].name === 'createdAt'
                    ? this.$t('modules.customdata.table.created_at')
                    : this.getLabelForAssociationHeader(columns[c], columns),
                  value: columns[c]?.association_name || columns[c].name,
                  type: columns[c].type,
                  width: columns[c].name === 'id'
                    ? '80'
                    : (columns[c].width || '200'),
                  sum: columns[c]?.sum,
                  non_editable: columns[c]?.non_editable,
                  display_type: columns[c]?.display_type,
                  click_action: columns[c]?.click_action,
                  file_upload: columns[c]?.file_upload,
                  display_in_table: columns[c]?.display_in_table || null, //image
                  column_from_association: associationColumns
                    ?.find((item) => item?.association?.model_field === columns[c].name || item?.name === columns[c].name) || null,
                  highlight_rules: columns[c]?.highlight_rules || null,
                  string_input: columns[c]?.string_input || null,
                  total_sum: columns[c]?.total_sum || false,
                }

                if (!columns[c]?.association || columns[c]?.association?.as) {
                  this.headers.push(item);
                }
              }

              this.headers.push({
                text: this.$t('modules.customdata.table.column_actions'),
                value: 'actions',
                type: 'actions',
                sortable: false,
                width: '50',
              });

              // set default columns for columns_chooser
              this.$store.dispatch('customDataSetDefaultColumnsData', this.headers)

              //operations with headers and columns_chooser
              if(this.custom_data_columns_data_activated.length) {
                this.$store.dispatch('customDataSetColumnsDataFromActivated')
              } else {
                if (this.custom_data_saved_columns_data.length) {
                  this.$store.dispatch('customDataSetColumnsData', this.custom_data_saved_columns_data)
                } else {
                  this.$store.dispatch('customDataSetColumnsData', this.headers)
                }
              }
            }
            //end update headers
          }
        },
        (err) => {
          this.$store.dispatch('updateAjaxDialogError', [ err, 'Ошибка', 'обновления' ]);
        }
      )
        .finally(() => {
          this.overlay = false
        });

      return data;
    },

    /**
     * Save button in edit modal
     * @method save
     */
    async save(_, modalNoClose = false) {
      let valid = false

      //Check form validation
      if (this.modal) {
        valid = this.$refs.modalMainEntityForm.$refs.form.validate()
      } else if (this.modalDynamic) {
        valid = this.$refs.modalEntityForm.$refs.form.validate()
      }
      //end Check form validation

      if (valid) {
        let request = this._.cloneDeep(this.item_obj);

        if (!request.id) {
          request.id = null;
        } else if (request.id && request.id === '') {
          request.id = null;
        }

        if (!request.kw_user_id) {
          request.kw_user_id = null;
        } else if (request.kw_user_id && request.kw_user_id === '') {
          request.kw_user_id = null;
        }

        for(let i in request) {
          if (typeof request[i] === 'object') {
            request[i] = request[i]?.id || null
          }

          if(!request[i]) {
            for (let headerItem of this.headers) {
              if (headerItem.file_upload === true && headerItem.name === i) {
                delete request[i];
              }
            }
          }

        }

        //TODO: fix save for user_id
        if (request?.consultant_name && this.modal_create_mode) {
          let selected_consultant = this.custom_data_linked_tables_values['consultant_name']
            .find((item) => item.name === request.consultant_name)

          request['user_id'] = selected_consultant?.is_id || null
        }
        //end TODO: fix save for user_id

        if (await this.saveCustomEntityList(request, request.id)) {
          this.getItems(false);

          if (!modalNoClose) {
            if(this.modalDynamic) {
              this.modalDynamic = false
              this.resetModalEntityForm()
            } else if(this.modal) {
              this.modal = false
              this.resetModalForm()
            }
          }
        }
      } else {
        return false;
      }
    },

    /**
     * @method saveCustomEntityList
     * @param entity
     * @param request
     * @param id
     * */
    async saveCustomEntityList(request, id) {
      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;

      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/update/${id}/?bot_id=${bot_id}`
      if(id===null)
        url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/create/?bot_id=${bot_id}`

      this.$store.dispatch('updateAjaxDialog', [true, true]);

      // Additional CRUD Filter
      if(
        this.additional_crud_filter?.enabled
        && this.additional_crud_filter_value === this.additional_crud_filter?.condition
      ) {
        request['additional_crud_filter'] = true
      }
      // end Additional CRUD Filter

      await axios
        .post(
          url,

          request,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            },
          }
        )
        .then(
          (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data &&
              response.data.status &&
              response.data.status === 'success'
            ) {
              success = true;
            }
          },
          (err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data?.message_alias
            ) {
              let message_alias = err.response.data.message_alias;

              if (message_alias === 'access_error') {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error'),
                  this.$t('common.error_permitions'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error') + '!',
                  this.$t('common.error_save'),
                ]);
              }
            } else if(
                err &&
                err.response &&
                err.response.data &&
                err.response.data.message?.original?.code === 'ER_WARN_DATA_TRUNCATED'
              ) {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error') + '!',
                  this.$t('common.error_type'),
                ]);
              } else if(
                err &&
                err.response &&
                err.response.data &&
                err.response.data.message?.original?.code === 'ER_DATA_TOO_LONG'
              ) {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error') + '!',
                  this.$t('rules.stringMaxLength'),
                ]);
              } else if(
                err &&
                err.response &&
                err.response.data &&
                err.response.data?.error
              ) {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error') + '!',
                  err.response.data.error,
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error') + '!',
                  this.$t('common.error_save'),
              ]);
            }
          }
        );

      return success;
    },

    /**
     * Edit item button
     * @method editItem
     * @param id
     * */
    async editItem(id) {
      //reset form when using dynamicModalEntity
      if (this.dynamicModalEntityAvailable) {
        this.resetModalEntityForm();
      }

      if (this.entity_data && id) {
        for (let item of this.entity_data) {
          if (item.id && parseInt(item.id) === parseInt(id)) {
            for (let param in item) {
              Vue.set(this.item_obj, param, item[param]);
            }

            this.openModal ()
          }
        }
      }
    },

    async mappingMultipleItems(mode) {
      if(mode === 'clone') {
        this.modal_multiple_entities_mode = true
      }
      this.selected_entities = []

      this.selected_entities = this._.cloneDeep(this.selected).reverse();

      if(this.selected_entities?.length) {
        this.modalMultiple = true
      }
    },

    /**Delete item button
     * @method deleteItem
     * @param id item id
     * */
    async deleteItem(id) {
      if (confirm(i18n.t('modules.customdata.entity.confirm_delete'))) {
        if (await this.deleteCustomEntityList(id)) {
          this.getItems(false);
        }
      }
    },

    /**Delete item button from Drawer
     * @method deleteItemDrawer
     * */
     async deleteItemDrawer() {
      let request = this._.cloneDeep(this.item_obj);

      if (confirm(i18n.t('modules.customdata.entity.confirm_delete'))) {
        if (await this.deleteCustomEntityList(request.id)) {
          this.getItems(false);

          if(this.modalDynamic) {
            this.modalDynamic = false;
            this.resetModalEntityForm()
          } else if(this.modal) {
            this.modal = false;
            this.resetModalForm()
          }
        }
      }
    },

    /**Delete selected items
     * @method deleteSelectedItem
     * @param array
     * */
    async deleteSelectedItem(arr) {
      if (confirm(i18n.t('modules.customdata.entity.confirm_delete'))) {
        try {
          const deletePromises = arr.map(item => this.deleteCustomEntityList(item.id));
          await Promise.all(deletePromises);
          this.getItems(false);
          this.selected = [];
        } catch (error) {
          console.error(error);
        }
      }
    },

    /**
     * Delete entity from the list
     * @method deleteCustomEntityList
     * @param entity
     * @param id
     */
    async deleteCustomEntityList(id) {
      let success = false;
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/delete/${id}/?bot_id=${bot_id}`

      // Additional CRUD Filter
      if(
        this.additional_crud_filter?.enabled
        && this.additional_crud_filter_value === this.additional_crud_filter?.condition
      ) url = url + '&additional_crud_filter=true'
      // end Additional CRUD Filter

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      await axios
        .delete(
          url,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            }

          }
        )
        .then(
          (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);
            if (
              response.data &&
              response.data.status &&
              response.data.status === 'success'
            ) {
              success = true;

              // this.$store.dispatch('axiosGetCurrentSectionNodesData');
            }
          },
          (err) => {
            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data?.message_alias
            ) {
              let message_alias = err.response.data.message_alias;

              if (message_alias === 'access_error') {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error') + '!',
                  this.$t('common.error_permitions'),
                ]);
              } else {
                this.$store.dispatch('updateAjaxDialogError', [
                  err,
                  this.$t('common.error') + '!',
                  this.$t('common.error_delete'),
                ]);
              }
            } else if(
              err &&
              err.response &&
              err.response.data &&
              err.response.data?.error
            ) {
              this.$store.dispatch('updateAjaxDialogError', [
                err,
                this.$t('common.error') + '!',
                err.response.data.error,
              ]);
            } else {
              this.$store.dispatch('updateAjaxDialogError', [
                err,
                this.$t('common.error') + '!',
                this.$t('common.error_delete'),
              ]);
            }
          }
        );

      return success;
    },

    /**
     * Export of custom data entities in file
     * @method getExportFile
     */
    getExportFile(fileType) {
      const token = Vue.$cookies.get('token_e')
      const engine_url = this.engine_settings.active_url;
      const bot_id = this.bot_settings.active_id;
      let url = `${engine_url}/kw/custom_modules/${this.multiple_entity}/export_data`

      //set filters
      const queryData = {}
      if(Object.keys(this.custom_data_selected_filters).length !== 0) {
        queryData['filter'] = this.setFiltersObject()
      }

      this.$store.dispatch('updateAjaxDialog', [true, true]);
      axios({
        method: 'POST',
        url: url,
        headers: {
          'X-CSRF-TOKEN': token,
        },
        params: {
          bot_id: bot_id,
          format: fileType,
          filename: 'bot_' + this.multiple_entity + '-' + bot_id,
        },
        data: queryData,
      })
        .then( (response) => {
            this.$store.dispatch('updateAjaxDialog', [false, false]);

            if(response?.status === 200) {
              this.$store.dispatch('updateAjaxDialog',
                [
                  true,
                  false,
                  '',
                  response?.data?.message_alias ?
                    this.$i18n.t(`notification.${response.data.message_alias}`)
                    : response?.data?.message,
                ]
              );
            }

            // this.$forceFileDownload(response, 'custom_data_' + this.multiple_entity + '-' + bot_id + '.' + fileType)
          },
          (err) => {
            this.$store.dispatch('updateAjaxDialogError', [ err, 'Ошибка', 'Скачивания' ]);
          }
        );

    },

    /**
     * @name clearModel
     * @description Clear all row in table
     * @returns {Promise<void>}
     */
    async clearModel(){
      if (confirm(i18n.t('modules.customdata.model.confirm_clear'))) {
        await this.CustomDataAxiosClearModel({name: this.multiple_entity})
        this.getItems()
      }
    },

    formatDateToString(date) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },

    openModal(createMode) {
      (createMode) ? this.modal_create_mode = true : this.modal_create_mode = false

      if(this.dynamicModalEntityAvailable && !createMode) {
        this.modalDynamic = true
      } else {
        this.modal = true
      }

      this.scrollChatToBottom()
    },

    /**
     * @name setFiltersObject
     * @description prepare Filters from selected and return URL
     */
    setFiltersObject () {
      let objectFilters = {}
      let requestFilter = Object.entries(this._.cloneDeep(this.custom_data_selected_filters))
        .filter(item => item[1]?.value)

      for (const [key, params] of requestFilter) {
        const pKey = key
        const type = params.type
        const association = params.is_association

        let condition = params.condition?.value
        let value = params.value

        switch (condition) {
          case 'empty':
            condition = 'eq'
            value = 'null'
            break;

          case 'like':
            condition = 'like'
            value = '%' + value + '%'
            break;

          case 'startsWith':
            condition = 'like'
            value = value + '%'
            break;

          case 'endsWith':
            condition = 'like'
            value = '%' + value
            break;

          case 'notLike':
            condition = 'notLike'
            value = '%' + value + '%'
            break;

          case 'active':
            condition = 'eq'
            value = 1
            break;

          case 'notActive':
            condition = 'eq'
            value = 0
            break;

          default:
            this.$consoleLogHandler('filter conditions no changed')
        }

        if (association) {
          if (value.length > 0) {
            objectFilters[pKey] = [];
            value.map(function(elVal) {
              objectFilters[pKey].push(elVal?.id);
            })
          }
        } else {
          if( (type === 'STRING' || type === 'TEXT') && value !== undefined) {
            objectFilters[pKey] = {[condition]: value}

            // if (condition === 'multiple' && value.length > 0) {
            //   value.map(function(elVal) {
            //     url += '&filter[' + pKey + '][in][]=' + elVal;
            //   })
            // } else {
            //   url += '&filter[' + pKey + '][' + condition + ']='+ value;
            // }
          } else if (['INTEGER', 'BIGINT']?.includes(type) && value !== undefined) {
            objectFilters[pKey] = {[condition]: value}

            // if ( (condition === 'between' || condition === 'notBetween') && value.length > 0 ) {
            //   value.map(function(elVal) {
            //     url += '&filter[' + pKey + '][' + condition + '][]=' + elVal;
            //   })
            // } else {
            //   url += '&filter[' + pKey + '][' + condition + ']=' + value;
            // }
          } else if (type === 'FLOAT' && value !== undefined) {
            objectFilters[pKey] = {[condition]: value}
            // if ( (condition === 'between' || condition === 'notBetween') && value.length > 0 ) {
            //   value.map(function(elVal) {
            //     url += '&filter[' + pKey + '][' + condition + '][]=' + elVal;
            //   })
            // } else {
            //   url += '&filter[' + pKey + '][' + condition + ']=' + value;
            // }
            // url += '&filter[' + pKey + '][like]=' + value;
          } else if (type === 'BOOLEAN' && value !== undefined && value !== null) {
            objectFilters[pKey] = {[condition]: value}
            // url += '&filter[' + pKey + '][' + condition + ']=' + value;
          } else if (type === 'DATE' && value !== undefined && value.length > 0) {

            //fix if one date in range v-date-picker
            if(value.length < 2){
              let secondDate = new Date(value[0])
              value.push(
                this.formatDateToString(
                  secondDate.setDate( secondDate.getDate() + 1) )
              )
            }

            //rise second date for back request
            if(value.length === 2) {
              let secondDate = new Date(value[1])
              value[1] = this.formatDateToString( secondDate.setDate( secondDate.getDate() + 1) )
            }

            objectFilters[pKey] = {gt: value[0], lt: value[1]}

            // url += '&filter[' + pKey + '][gt]=' + value[0];
            // url += '&filter[' + pKey + '][lt]=' + value[1];
          }
        }
      }

      //filters from Global
      //TODO: only createdAt
      if(this.dates.length === 2) {
        const dateFrom = new Date(this.dates[0])
        let secondDate = new Date(this.dates[1])
        const dateTo = this.formatDateToString( secondDate.setDate( secondDate.getDate() + 1) )

        objectFilters['createdAt'] = {
          gt: dateFrom,
          lt: dateTo,
        }
      }


      return objectFilters
    },

    async resetColumnsChooser() {
      this.headerColumns = []
      await this.customDataResetColumnsDataToDefault(this.multiple_entity)
      this.setHeaderColumnsFromState()
      // this.headerColumns = this._.cloneDeep(this.custom_data_columns_data)
    },

    async resetColumnsChooserForFilter() {
      this.headerColumns = []
      await this.customDataResetColumnsDataForFilterToSaved()
      this.setHeaderColumnsFromState()
      // this.headerColumns = this._.cloneDeep(this.custom_data_columns_data)
    },

    /**
     * @name onSaveFilters
     * @description save and update filters
     * @param data
     * @returns {Promise<void>}
     */
    async onSaveFilters(data){
      let { name, with_columns } = data
      let selectedFilters = Object.entries(this._.cloneDeep(this.custom_data_selected_filters))
        .filter(item => item[1]?.value)

      await this.customDataAxiosSaveFilters(
        {
          nameModule: this.multiple_entity,
          nameFilter: name,
          filters_json: selectedFilters,
          with_columns: with_columns,
        }
      )

      if(!name) {
        this.$store.dispatch('customDataSetIsGlobalColumns', false)
      }
    },

    /**
     * @name onDeleteFilter
     * @description delete filter
     * @param name
     */
    onDeleteFilter(name){
      this.$store.dispatch('customDataAxiosDeleteFilter',
        {
          nameModule: this.multiple_entity,
          nameFilter: name,
        }
      )

      if(!name) {
        this.$store.dispatch('customDataSetIsGlobalColumns', true)
        this.$store.dispatch('customDataResetColumnsDataToDefault', null)
        // this.resetColumnsChooserForFilter()
      }
      this.setHeaderColumnsFromState();
    },

    setHeaderColumnsFromState() {
      let columns = this._.cloneDeep(this.custom_data_columns_data)

      this.headerColumns = this._.orderBy(columns, 'position')
    },

    changeTabRoute(item) {
      if(this.$route?.params?.model_name !== item.name)
        this.$router.push({ params: { model_name: item.name } });
    },

    handleSearchInput(onEnter) {
      if (this.timeoutId) clearTimeout(this.timeoutId);

      (onEnter)
        ? this.getItems(false)
        : this.timeoutId = setTimeout(this.getItems(false), 2000)
    },

    scrollToComponent() {
      setTimeout(()=>{
        const component = this.$refs.DynamicStatistics;
        component?.$el.scrollIntoView({ behavior: 'smooth' });
      },500) 
    },

    disableSearchActive() {
      setTimeout(() => {
        this.isSearchActive = false;
      }, 500);
    },

    datepickerDisabledInFuture(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date > today
    },

    changeCdActions() {
      this.cdActionsMobileView = !this.cdActionsMobileView
      this.cdActionsMobileView ? this.drawer = false : this.showFilters = false
    },

    getLabelForAssociationHeader(element, elements){
      const associationColumns = elements.filter((c) => c?.association)
      const labelFromAssociation = associationColumns
        .find(
          (el) =>
            el.name === element?.name ||
            el.name === element?.association_labels?.[this.multiple_entity] ||
            el.name === element?.association_label
        )

      return labelFromAssociation?.label || element?.label || element.name
    },

    resetModalForm(validation = null) {
      validation
        ? this.$refs?.modalMainEntityForm.$refs.form.resetValidation()
        : this.$refs?.modalMainEntityForm.$refs.form.reset()
    },

    resetModalEntityForm(validation = null) {
      if(this.$refs?.modalEntityForm) {
        validation
          ? this.$refs?.modalEntityForm.$refs.form.resetValidation()
          : this.$refs?.modalEntityForm.$refs.form.reset()
      }
    },

    //Permissions
    checkPermission(can){
      let available = true
      const bot_id = this.$route?.params?.id

      if(
        this.user.role.permissions?.custom_data?.models?.[bot_id] &&
        this.multiple_entity
      ) available = this.user.role.permissions.custom_data.models[bot_id][this.multiple_entity][can]

      return available
    },
    //end Permissions

    //multiple modal entities
    getModalMultipleEntitiesByField(field) {
      return !!this.custom_data_tables
        .find((t) => t.name === this.multiple_entity && t?.front_json?.modal_multiple_entities?.[field]);
    },

    //get custom stylization
    getUITableStylization() {
      return this.custom_data_tables
        .find((t) => t.name === this.multiple_entity && t?.front_json?.table_stylization)?.front_json?.table_stylization;
    },
  },

  watch: {
    modal: function (val) {
      if (!val && this.item_obj?.id) {
        this.resetModalForm('validation')
        this.item_obj = this._.cloneDeep(this.item_empty_obj);
      }
    },

    modalDynamic: function (val) {
      if (!val && this.item_obj?.id) {
        this.resetModalEntityForm('validation')
        this.item_obj = this._.cloneDeep(this.item_empty_obj);
      }
    },

    multiple_entity: async function (value, oldValue) {
      //clear headers
      this.headerColumns = []
      this.dataInitial = JSON.parse(JSON.stringify(this.dafaultDataInitial));
      await this.$store.dispatch('customDataSetColumnsData', [])
      await this.$store.dispatch('customDataSetColumnsDataFromActivated', 'clear')

      await this.$store.dispatch('customDataClearLinkedTableValues');

      // Additional CRUD filter
      this.additional_crud_filter = this.custom_data_tables
        .find((t) => t.name === value && t?.front_json?.additional_crud_filter)?.front_json?.additional_crud_filter || {}

      if(
        this.additional_crud_filter
        && Object.keys(this.additional_crud_filter)?.length
      ) {
        this.additional_crud_filter_value = this.additional_crud_filter?.default_value || false
      }
      // end Additional CRUD filter

      //get filters
      await this.$store.dispatch('customDataAxiosGetFilters', {
        multiple_entity: value,
        botId: this.$route?.params?.id
      })

      //Apply filter active
      if(this.custom_data_saved_filters?.length && this.$route?.query?.filter) {
        let filter = this.custom_data_saved_filters.find(({name}) => name === this.$route.query.filter)

        if (filter) {
          await this.customDataSetToggleActiveFilter(filter);
        } else if (oldValue || !filter) {
          await this.$router.replace({'query': null});
        }
      }
      //end Apply filter active

      //get rows and headers
      this.entity_data = await this.getCustomEntityList();

      // this.headerColumns = await this._.cloneDeep(this.custom_data_columns_data)
      this.setHeaderColumnsFromState()

      this.dynamicStatisticsShowed = false;
      this.dynamicStatisticsAvailable = !!this.custom_data_tables.find((t) => t.name === value && t?.statistics_json?.length);

      //enable new Modal Entity if front_json has key modal_entity
      this.dynamicModalEntityAvailable = !!this.custom_data_tables.find((t) => t.name === value && t?.front_json?.modal_entity);

      //model macros
      this.macros_params = this.custom_data_tables.find((t) => t.name === value && t?.front_json?.macros)?.front_json?.macros

      //show table
      if (!this.load) this.load = true;
    },
  },
};
</script>

<style scoped lang="scss">
.app-wrapper > div > div > div:not(.v-overlay__content) {
  height: auto;
}

.page-title {
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
}

.cd {
  &__wrapper {
    height: calc(100vh - 60px);
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      height: calc(100svh - 60px);
    }
  }
  &__drawer {
    box-shadow: 0 5px 15px rgba(77, 76, 172, 0.12);

    //toggle button filter
    &--toggle-filter {
      position: relative;
      left: -20px;
      display: flex;
      align-items: center;

      &__select {
        max-width: 30px;

        &::v-deep {
          .v-input__slot {
            border-radius: 0;
            padding-right: 0 !important;
          }
          .v-select__selections {
            display: none !important;
          }
        }
      }

      &__btn-arrow {
        border-radius: 0 20px 20px 0 !important;
      }
    }

    &--wrapper {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      height: 100%;

      &-top {
        flex: 0 1 auto;
      }

      &-body {
        flex: 1 1 auto;
      }
    }

    &--select::v-deep {
      .v-input__slot {
        padding-right: 0 !important;
      }
      &.v-overflow-btn--segmented .v-select__selections .v-btn{
        height: 38px;
      }
      .v-input__append-inner {
        height: 38px;
        margin-top: 0 !important;
      }
    }

    //filters
    &-filters {
      &__item {
        &:not(:first-child){
          margin-top: 16px;
        }

        &-title{
          margin-bottom: 8px;
        }
      }

      &__date {
        width: 100%;
      }
    }
    //filters
  }

  &__container {
    flex: 1 1 auto;
    min-width: 0;
    padding: 16px 12px 22px;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: calc(100vh - 60px);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      height: calc(100svh - 60px);
      padding: 16px 0 10px;
    }

    &.statistics-showed {
      height: auto;
    }
  }

  &__left {
    min-width: 216px;
    max-width: 216px;
    width: 216px;
    padding-left: 8px;
    padding-right: 8px;
    height: 100%;
  }

  &__right {
    padding-left: 8px;
    padding-right: 8px;
    flex: 1 1 auto;
    height: 100%;
    min-width: 0;
    //width: calc(100% - 216px);
    position: relative;

    .table-descr {
      font-size: 18px;
      font-weight: bold;
      position: absolute;
      top: -35px;
      left: 10px;
      color: var(--v-primary-base)
    }
  }
}

/* Top navigation for Table */
.cd__top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;

  .cd__right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 0.5em;
  }

  .cd__actions {
    display: flex;
  }

  .cd__tabs {
    width: 100%;

    &--select {
      &.v-select{
        max-width: 250px;
      }
    }


    &:after {
      content: '';
      display: block;
      height: 1px;
      background: var(--v-blueHaze-base);
      margin-right: 52px;
      margin-left: 52px;
      margin-top: -1px;
    }
    


    .v-tab {
      border-bottom: 1px solid var(--v-blueHaze-base);
      font-size: 0.875rem;
      padding: 0 10px;
      text-transform: none;
    }
  }

  .v-input {
    max-width: 360px;
  }

  .v-btn::v-deep {
    border-radius: 5px;
    padding: 0 10px;
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0.04em;
  }

  .v-select {
    max-width: 134px;
    font-size: 14px;
  }
}

/* end Top navigation for Table */

/* Filters and Table */
.cd__bottom-nav {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
}

.cd__table {
  display: flex;
  flex-direction: column;
}

/* menus with list */
.v-custom-menu__list{
  border: 1px solid var(--v-gray-base);

  &--btn {
    &:focus:before {
      opacity: 0;
    }
    &[aria-expanded="true"] {
      .v-icon {
        color: var(--v-primary-base) !important;
      }
    }

    &.v-btn--text{

      &[aria-expanded="true"] {
        .v-btn__content {
          color: var(--v-primary-base) !important;
        }

        .v-icon.mdi-chevron-down {
          transform: rotate(180deg);
        }
      }
    }
  }

  &--wrapper::v-deep {
    &.with_fixed-height {
      //height: 380px;
    }

    .__rail-is-vertical {
      right: 0 !important;
    }

    .v-list-group {
      &__items {
        background-color: var(--v-gray2-base) !important;

        .v-list-item__content {
          padding: 0 64px !important;
        }
      }
    }

  }

  .v-list {
    padding: 5px 0 10px;

    .v-subheader {
      height: auto;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      padding: 15px 20px 5px;
    }

    .v-list-item {
      //min-height: 32px;
      //max-height: 34px !important;

      &.v-list-item--active::before {
        opacity: 0;
      }

      .v-list-item__action{
        margin: 5px 12px 4px 0;
      }
      .v-list-item__content {
        padding: 6px 0;
        .v-list-item__title {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  &--filter-btns::v-deep {
    flex: none;
    transition: all 0.25s;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 5px;
      pointer-events: none;
      background-color: currentColor;
      opacity: 0;
    }

    &:hover {

      .theme--light.v-divider {
        border-color: white !important;
        z-index: 1;
      }

      &::before {
        opacity: 0.08;
      }
    }

    .theme--light.v-divider {
      border-color: var(--v-primaryBackground-base) !important;
    }

    &.active{
      border-color: var(--v-gray-base);

      &::before {
        opacity: 0.08;
      }
    }

    .v-btn {
      border: none !important;
      height: 100% !important;
      //min-width: inherit !important;
      //width: auto !important;
    }

    .filter-btns {
      &__btn-show {
        min-width: 36px !important;

        &:hover::before {
          opacity: 0;
        }
      }
      &__btn-menu {
        //flex: auto !important;
        min-width: 28px !important;
        padding: 0 6px !important;

        &:focus::before {
          opacity: 0;
        }
      }
    }
  }
}

.draggable-ghost {
  opacity: 0.5;
  background: var(--v-primaryLight-base);
}
/* end menus with list */

.settings-form::v-deep {
  height: 100%;

  .v-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    .v-card__text{
      flex-grow: 1;
      overflow: auto;
    }
  }

  .mx-datepicker {
    width: 100%;
    margin-bottom: 20px;

    .mx-datepicker-main.mx-datepicker-popup {
      left: 0!important;
    }
    .mx-input {
      height: 44px;
      border-color: #9e9e9e;
    }
  }

  .settings-form__field {
    padding: 0 !important;

    &--hidden {
      display: none !important;
    }
  }
}

.modal__close-btn {
  z-index: 1;
  top: 9px;
}

.modal__menu-btn {
  z-index: 1;
  top: 48px;

  &::before {
    opacity: 0.08;
  }
}

.v-navigation-drawer:not(.cd__drawer) {
  &::v-deep .v-navigation-drawer__border {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 0, 0, 0.08) !important;
    
    &:after {
      content: '·······';
      color: #000;
      transform: rotate(90deg);
      position: absolute;
      top: 50%;
      left: -14px;
      font-size: 24px;
      line-height: 0.3;
      pointer-events: none;
    }

    @media screen and (max-width: 959px){
      display: none;
    }
  }

  .navigation-drawer {

    &__title {
      font-size: 18px;
      margin-top: 30px;
      padding-left: 27px;
      padding-right: 50px;
      padding-bottom: 10px;
      text-align: left;
    }

    &__wrap {
      padding: 20px 15px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.38);
      margin-bottom: 25px;

      .cd__image-slider {
        display: flex !important;
        gap: 16px !important;
        height: 400px !important;
      }
    }

    &__item-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
      text-align: left;
    }

  }

}

.custom-data__search::v-deep {

  .v-input {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: 5px;
      pointer-events: none;
      background-color: currentColor;
      opacity: 0;
      transition: all 0.25s;
    }

    &:hover::before {
      opacity: 0.08;
    }

    &--is-focused {
      width: 100%;

      .v-input__slot {
        cursor: text;
        
        &:after {
          width: 100%;
        }
      }

      input {
        cursor: text !important;
        width: 165px !important;
      }
    }
  }

  .v-input__slot {
    cursor: pointer;
    padding: 0 10px 0 5px;

    &::before {
      content: none;
    }

    &:after {
      border-bottom-width: 0;
      transform: none;
      width: 0;
    }

    input {
      cursor: pointer;
      width: 45px;
      transition: all 0.4s;
    }
  }

  .v-icon {
    font-size: 18px;
    color: inherit;
  }

  .v-text-field__slot > * {
    color: inherit;
    font-size: 13px;
  }
}

// new styles for mobile view of Custom Data
.cd__drawer {
  z-index: 5;
}
.col-chooser-btn-wrap {
  position: sticky;
  bottom: 0;
  background: #fff;
  background: linear-gradient(0deg, #fff 80%, transparent 100%);
}

.cd__tabs-mobile-toggle + .cd__tabs-mobile-toggle {
  display: none;
}
.cd__actions--toggle-actions__btn-arrow {
  display: none;
}

.cd__drawer--wrapper-top {
  display: grid;
  grid-template-columns: auto 40px;
  align-items: center;
}

.cd__drawer-close {
  padding: 8px;
  position: relative;
  right: -8px;
}

// Global Filter Styles
.cd__drawer-global-filter-item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 8px;
  background-color: #f5f6fa;
  border: 1px solid #ccc;
  border-radius: 4px;

  &-content {
    flex: 1 0 calc(100% - 32px);
  }
  &-delete {
    flex: 0 0 24px;
  }

  .v-input {
    background-color: #fff;
  }
  .v-input + .v-input {
    margin-top: 8px;
  }
}

@media screen and (max-width: 1299px) {
  .cd__tabs--hide-for-cd-actions {
    visibility: hidden;
  }
  .cd__actions--toggle-actions__btn-arrow {
    display: block;
    border-radius: 20px 0 0 20px !important;
    position: absolute;
    right: -12px;
    top: 0;
    z-index: 1;
  }
  .cd__tabs-mobile-toggle {
    display: none;
  }
  .cd__tabs-mobile-toggle + .cd__tabs-mobile-toggle {
    display: block;
  }
  .cd__tabs-tooltip-for-select {
    margin-left: 20px;
  }
  .cd__top-nav .cd__tabs--select.v-select {
    min-width: 150px;
  }
  .cd__top-nav .cd__tabs--select::v-deep .v-select__slot {
    pointer-events: none;
  }
  .cd__actions {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    background-color: #f5f6fa;
    position: absolute;
    right: 0;
    top: -5px;
    transform: translate(calc(100% + 12px), 0);
    transition: transform 0.3s ease;

    &.cd__actions--visible {
      transform: translate(0, 0);
      left: 0;
      right: 0;
      padding-right: 45px;
      overflow-x: auto;
    }
  }
  .cd__top-nav {
    margin-bottom: 10px;
  }
  .dynamic-table::v-deep {
    //.v-data-table-header th:last-child span {
    //  display: none;
    //}
    //tbody tr:not(.total-row) td:last-child {
    //  background-color: #fff;
    //  position: sticky;
    //  right: 0;
    //  z-index: 1;
    //
    //  &:before {
    //    content: "";
    //    width: 1px;
    //    height: 100%;
    //    background-color: #d0d4e4;
    //    position: absolute;
    //    left: -1px;
    //    top: 0;
    //  }
    //
    //  .v-btn--icon.v-size--default {
    //    width: auto;
    //  }
    //}
    .table-wrapper {
      margin-bottom: 10px;
    }
    .table-pagination {
      justify-content: space-between;
      flex-wrap: wrap;
      position: static;
      transform: translateY(0);
    }
  }
}
@media screen and (max-width: 767px) {
  .cd__bottom-nav {
    position: relative;
  }
  .cd__left {
    height: 100%;
    position: absolute;
    left: -8px;
    top: 0;
    z-index: 5;

    &::v-deep .filter-title {
      border-top: 1px solid #b0bec8;
      border-right: 2px solid #b0bec8;
    }
    &::v-deep .filter-body + hr + div {
      border-bottom: 1px solid #b0bec8;
      border-right: 2px solid #b0bec8;
    }
  }
  .cd__actions {
    &-mobile-hide {
      display: none;
    }

    .v-btn {
      min-width: 40px;

      &::v-deep .v-btn__content .v-icon--left {
        margin-left: 0;
      }

      i {
        margin-right: 0;
      }
    }

    .custom-data__search {
      width: 40px;
      min-width: 40px;
      position: relative;

      &::v-deep {
        label {
          display: none;
        }
        .v-text-field__slot {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;

          input {
            padding-left: 30px;
          }
        }
        .v-input__prepend-inner {
          position: relative;
          z-index: 2;
        }
        .v-input--is-focused {
          border-bottom: none;
          &:before {
            display: none;
          }
          .v-text-field__slot {
            opacity: 1;
            background-color: #fffbff;
            border-bottom: 1px solid #5567ff;
          }
          .v-input__slot:after {
            display: none;
          }
        }
      }
    }
  }

  .dynamic-table::v-deep {
    .table-pagination {
      .mr-5 {
        margin-right: 0 !important;
      }
      .v-pagination__more,
      button {
        margin: 0 2px;
      }
    }
  }
}


.custom-data__file {

  &--item {
    border: 1px solid rgba(0, 0, 0, .38);
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
    flex-wrap: wrap;
    gap: 8px;
    min-height: 40px;

    .file-item-link {
      position: relative;
    }

    .file-item-del {
      position: absolute;
      top: -3px;
      right: -3px;
      opacity: 0.8;
      transition: all 0.25s;

      &:hover {
        opacity: 1;
      }
    }
  }

  &--label {
    text-align: left;
    font-size: 16px;
  }

}

.addition_crud_filter {
  &::v-deep {
    .v-label {
      font-size: 13px;
      white-space: nowrap;
    }
  }
}

</style>
